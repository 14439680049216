.facility-select, .organization-select, .year-select {
  padding: 16px 8px;
}

.facility-select.disabled, .organization-select.disabled {
  pointer-events: none;
}

.facility-select.disabled svg, .organization-select.disabled svg {
  display: none;
}